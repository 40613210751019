import type { Organism } from '@verndale/core';
import globModules from './glob-modules';

const reactModule = (Component: any, nodeList: NodeListOf<HTMLElement>) => {
  const React = require('react');
  const { createRoot } = require('react-dom/client');

  return nodeList.forEach(node =>
    createRoot(node).render(<Component {...node.dataset} />)
  );
};

const modules: Organism[] = [
  {
    name: 'header',
    loader: () => import('./modules/header'),
  },
  {
    name: 'fullWidthVideoRow',
    styles: () => import('../scss/modules/full-width-video-row.scss')
  },
  {
    name: 'chapterMediaRow',
    styles: () => import('../scss/modules/chapter-media-row.scss'),
    loader: () => import('./modules/chapterMediaRow')
  },
  {
    name: 'contentWithMediaRow',
    styles: () => import('../scss/modules/content-with-media-row.scss'),
    loader: () => import('./modules/contentWithMediaRow')
  },
  {
    name: 'featuredCallToActions',
    styles: () => import('../scss/modules/featured-call-to-actions.scss'),
    loader: () => import('./modules/featuredCallToActions')
  },
  {
    name: 'shareModule',
    loader: () => import('./modules/shareModule'),
    styles: () => import('../scss/modules/share-module.scss')
  },
  {
    name: 'podcastModule',
    styles: () => import('../scss/modules/podcast-module.scss')
  },
  {
    name: 'carouselAndFutureInsights',
    loader: () => import('./modules/carouselAndFeaturedInsights'),
    styles: () => import('../scss/modules/carousel-and-featured-insights.scss')
  },
  {
    name: 'vidyardVideo',
    loader: () => import('./components/vidyardVideo'),
    styles: () => import('../scss/components/vidyard-video.scss')
  },
  {
    name: 'videoButton',
    loader: () => import('./components/videoButton'),
    styles: () => import('../scss/components/_video-button.scss')
  },
  {
    name: 'footer',
    loader: () => import('./modules/footer'),
    styles: () => import('../scss/modules/footer.scss')
  },
  {
    name: 'newsTabbedModule',
    loader: () => import('./modules/tabs'),
    styles: () => import('../scss/modules/news-tabbed-module.scss')
  },
  {
    name: 'productFeatureTabs',
    loader: () => import('./modules/tabs'),
    styles: () => import('../scss/modules/product-feature-tabs.scss')
  },
  {
    name: 'generalTabbedModule',
    loader: () => import('./modules/tabs'),
    styles: () => import('../scss/modules/general-tabbed-module.scss')
  },
  {
    name: 'quoteBlock',
    loader: () => import('./modules/quoteBlock'),
    styles: () => import('../scss/modules/quote-block.scss')
  },
  {
    name: 'contactUs',
    styles: () => import('../scss/modules/contact-us.scss')
  },
  {
    // init before intro-animation in order to listen for events
    name: 'homepageMasthead',
    loader: () => import('./modules/homepageMasthead')
  },
  {
    name: 'ctaCollectionBlock',
    styles: () => import('../scss/modules/cta-collection-block.scss')
  },
  {
    name: 'productLayers',
    loader: () => import('./modules/productLayers')
  },
  {
    name: 'columnCard',
    styles: () => import('../scss/modules/column-card.scss'),
    loader: () => import('./modules/columnCard')
  },
  {
    name: 'simpleGraphCard',
    styles: () => import('../scss/modules/simple-graph-card.scss'),
    loader: () => import('./modules/simpleGraphCard')
  },
  {
    name: 'productFeatureImage',
    loader: () => import('./modules/productFeatureImage'),
    styles: () => import('../scss/modules/product-feature-image.scss')
  },
  {
    // any module listening for events from this module must be loaded before this module
    name: 'introAnimation',
    loader: () => import('./modules/introAnimation')
  },
  {
    name: 'statsBlock',
    loader: () => import('./components/statsBlock'),
    styles: () => import('../scss/modules/stats-block-module.scss')
  },
  {
    name: 'sponsorsBlock',
    styles: () => import('../scss/modules/sponsors-block-module.scss')
  },
  {
    name: 'featuredCaseStudy',
    loader: () => import('./modules/featuredCaseStudy'),
    styles: () => import('../scss/modules/featured-case-study.scss')
  },
  {
    name: 'logoCarousel',
    loader: () => import('./components/logoCarousel'),
    styles: () => import('../scss/components/logo-carousel.scss')
  },
  {
    name: 'borderedRichText',
    styles: () => import('../scss/modules/bordered-rich-text.scss')
  },
  {
    name: 'genericContainerBlock',
    styles: () => import('../scss/modules/generic-container-block.scss')
  },
  {
    name: 'ctaModule',
    styles: () => import('../scss/modules/cta-module.scss')
  },
  {
    name: 'accordion',
    loader: () => import('./modules/accordion'),
    styles: () => import('../scss/modules/accordion.scss')
  },
  {
    name: 'numberedList',
    loader: () => import('./modules/numberedList'),
    styles: () => import('../scss/modules/numbered-list.scss')
  },
  {
    name: 'quotedTextModule',
    styles: () => import('../scss/modules/quoted-text-module.scss')
  },
  {
    name: 'peopleCard',
    loader: () => import('./components/peopleCard')
  },
  {
    name: 'pressReleasesListing',
    styles: () => import('../scss/modules/press-releases-listing.scss'),
    loader: () => import('./modules/pressReleasesListing'),
    render: reactModule
  },
  {
    name: 'cardDetailsModal',
    loader: () => import('./components/cardDetailsModal')
  },
  {
    name: 'eventCardListing',
    styles: () => import('../scss/modules/event-card-listing.scss'),
    loader: () => import('./modules/eventCardListing'),
    render: reactModule
  },
  {
    name: 'quote',
    styles: () => import('../scss/components/quote.scss'),
    loader: () => import('./components/quote')
  },
  {
    name: 'caseStudyResults',
    styles: () => import('../scss/modules/case-study-results.scss')
  },
  {
    name: 'search',
    styles: () => import('../scss/modules/search/search.scss'),
    loader: () => import('./modules/search'),
    render: reactModule
  },
  {
    name: 'achievementsModule',
    styles: () => import('../scss/modules/achievements-module.scss'),
    loader: () => import('./modules/achievementsModule')
  },
  {
    name: 'resourceTopicCard',
    loader: () => import('./modules/resourceTopicCard'),
    styles: () => import('../scss/modules/resource-topic-card.scss')
  },
  {
    name: 'marketoForm',
    loader: () => import('./modules/marketoForm'),
    styles: () => import('../scss/modules/marketo-form.scss')
  },
  {
    name: 'taggedCardsListing',
    loader: () => import('./modules/taggedCardsListing'),
    render: reactModule
  },
  {
    name: 'featuredItemsListing',
    loader: () => import('./modules/featuredItemsListing'),
    render: reactModule
  },
  {
    name: 'circleGraph',
    loader: () => import('./components/circleGraph')
  },
  {
    name: 'simpleBarGraph',
    loader: () => import('./components/simpleBarGraph')
  },
  {
    name: 'gatedFormModal',
    loader: () => import('./components/gatedFormModal')
  },
  {
    name: 'productTourOverview',
    styles: () => import('../scss/modules/product-tour-overview.scss')
  },
  {
    name: 'productTourListing',
    styles: () => import('../scss/modules/product-tour-listing.scss'),
    loader: () => import('./modules/productTourListing'),
    render: reactModule
  },
  {
    name: 'whitePapers',
    loader: () => import('./modules/whitePapers')
  },
  {
    name: 'longForm',
    loader: () => import('./modules/longForm')
  },
  {
    name: 'jumplinksContainer',
    loader: () => import('./modules/jumplinksContainer')
  },
  {
    name: 'showHideContainer',
    loader: () => import('./modules/showHideContainer')
  },
  {
    name: 'optiForm',
    loader: () => import('./modules/optiForm')
  }
];

export default [...globModules, ...modules];
